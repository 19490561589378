




























import { EntityType, EntityTypeOption, ProjectFormType } from '@/api/project/model'
import { basicItems, sessionItems } from '@/api/project/entity-type-option'
import { defineComponent, Ref, ref } from '@vue/composition-api'
import { Popover } from 'element-ui'

export default defineComponent({
    components: {
        [Popover.name]: Popover
    },
    props: {
        formType: {
            type: String,
            default: ProjectFormType.Basic
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'right'
        },
        trigger: {
            type: String,
            default: 'hover'
        }
    },
    setup(props, { emit }) {
        const isShow = ref(false)

        const optionItems: Ref<EntityTypeOption[]> = ref([])
        if (props.formType == ProjectFormType.Basic) {
            optionItems.value = [...basicItems]
        } else if (props.formType == ProjectFormType.Session) {

            optionItems.value = sessionItems.filter((v) => v.value != EntityType.branch)
        }
        const onSelect = (val: any) => {
            emit('select', val)
            isShow.value = false
        }
        return {
            isShow,
            optionItems,
            onSelect
        }
    }
})
