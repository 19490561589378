

























import { computed, defineComponent, provide, reactive, watch, inject } from '@vue/composition-api'
import { LocaleType } from '@/interface/common'
import BasicPoll from './BasicPoll.vue'
import BasicAskOpinion from './BasicAskOpinion.vue'
import Speak from './Speak.vue'
import Poll from './Poll.vue'
import Rank from './Rank.vue'
import AskOpinion from './AskOpinion.vue'
import AskExperience from './AskExperience.vue'
import VideoVue from './Video.vue'
import ImageVue from './Image.vue'
import Branch from './Branch.vue'
import Upload from './Upload.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import _ from 'lodash'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
    components: {
        BasicPoll,
        BasicAskOpinion,
        Speak,
        Poll,
        Rank,
        AskOpinion,
        AskExperience,
        VideoVue,
        ImageVue,
        Branch,
        Upload,
        ErrorMessage
    },
    props: {
        serial: Number,
        formType: {
            type: String,
            default: ProjectFormType.Basic
        },
        itemObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        detailObj: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    setup(props, { root }) {
        const detailObj: any = inject('detailObj')

        provide('formType', props.formType)
        const itemObj = reactive(_.cloneDeep(props.itemObj))
        if (!itemObj.message_entity.settings) {
            itemObj.message_entity.settings = []
        }

        if (itemObj.message_entity.settings.length === 0) {
            itemObj.message_entity.settings.push({
                ai_enable: ![LocaleType.en_US,LocaleType.zh_CN,LocaleType.zh_TW].includes(detailObj.language),
                options: {
                    randomized: false,
                    other: false,
                    none: false,
                },
                recommend_enable: true,
            });
        }
        provide('itemObj', itemObj)
        const entity_type = computed(() => itemObj.message_entity.entity_type)
        return {
            EntityType,
            entity_type,
            ProjectFormType
        }
    }
})
