





































































import { showError } from '@/utils/common'
import { defineComponent, reactive, toRefs, ref, computed,onMounted } from '@vue/composition-api'
import { Switch, Checkbox } from 'element-ui'
import { EntityType } from '@/api/project/model'
import { useSettingStore } from '@/pinia/modules/setting'
import { LocaleType } from '@/interface/common'
import { wordColors, wordShapes } from '@/utils/word-cloud'
import { ConversationStatusType } from '@/api/project/model'
import { entityTypeTemplate } from '../../../api/project/entity-type-template'
import { updateOrCreateConversationMessageApi } from '@/api/project'
import WordCloudShape from '@/components/WordCloudShape.vue'
import { cloneDeep } from 'lodash'
import ErrorMessage from '@/components/ErrorMessage.vue'
export default defineComponent({
    components: {
        [Switch.name]: Switch,
        [Checkbox.name]: Checkbox,
        WordCloudShape,
        ErrorMessage
    },
    props: {
        fromPage: {
            type: String,
            default: 'ProjectSurvey'
        }
    },
    setup(props, { root, emit }) {
        const errorMessage = ref()
        const userPromptValue = ref()
         const localeOptions:any = ref([])
        const buildAndSortLocaleOptions = () => {
            let options = [
                {
                    name: root.$i18n.t('locale.en'),
                    value: LocaleType.en_US
                },
                {
                    name: root.$i18n.t('locale.zh_CN'),
                    value: LocaleType.zh_CN
                },
                {
                    name: root.$i18n.t('locale.zh_TW'),
                    value: LocaleType.zh_TW
                },
                {
                    name: root.$i18n.t('locale.vi_VN'),
                    value: LocaleType.vi_VN
                },
                {
                    name: root.$i18n.t('locale.fr_FR'),
                    value: LocaleType.fr_FR
                },
                {
                    name: root.$i18n.t('locale.es_ES'),
                    value: LocaleType.es_ES
                },
                {
                    name: root.$i18n.t('locale.id_ID'),
                    value: LocaleType.id_ID
                }, {
                    name: root.$i18n.t('locale.tr_TR'),
                    value: LocaleType.tr_TR
                }, {
                    name: root.$i18n.t('locale.th_TH'),
                    value: LocaleType.th_TH
                }, {
                    name: root.$i18n.t('locale.ja_JP'),
                    value: LocaleType.ja_JP
                }, {
                    name: root.$i18n.t('locale.es_MX'),
                    value: LocaleType.es_MX
                }, {
                    name: root.$i18n.t('locale.ms_MY'),
                    value: LocaleType.ms_MY
                },{
                    name: root.$i18n.t('locale.sv_SE'),
                    value: LocaleType.sv_SE
                }
            ];

            // 获取所有名称并排序
           const sortedOptions = options.sort((a:any, b:any) => a.name.localeCompare(b.name));
            
            localeOptions.value = sortedOptions;
        }
        
        const locale = ref('zh-CN')
        const instruct = ref('')
        const state = reactive({
            show: false,
            loading: false,
            sectionInfo: {} as any,
            onSelectColor: (item: { color: string; list: string[] }) => {



                state.sectionInfo.message_entity.nlp_settings.color = item.color
            },
            onSelectShape: (item: { icon: string; shape: string }) => {



                state.sectionInfo.message_entity.nlp_settings.shape = item.shape
            },
            onOpen: (item: any) => {
                state.sectionInfo = cloneDeep(item)

                if (!state.sectionInfo.message_entity.nlp_settings) {
                    state.sectionInfo.message_entity.nlp_settings = cloneDeep(entityTypeTemplate.nlp_settings)
                }

                state.sectionInfo.message_entity.nlp_settings.apply_global_color = false
                state.sectionInfo.message_entity.nlp_settings.apply_global_shape = false

                state.show = true
            },
            onSave: async () => {
                if (state.sectionInfo.message_entity.entity_type == EntityType.upload_image) {
                    if (state.sectionInfo.message_entity.ai_settings.ai_enable && state.sectionInfo.message_entity.ai_settings.theme == '') {
                        errorMessage.value = root.$t('projectSurvey.AIAnalyze.errorMessage3')
                        return
                    } else {
                        errorMessage.value = ''
                    }
                }
                state.loading = true
                try {
                    if (props.fromPage == 'ProjectSurvey') {
                        const data = await updateOrCreateConversationMessageApi(state.sectionInfo)
                        emit('success', 'edit', data)
                    } else if (props.fromPage == 'ProjectLive') {
                        const data = await updateOrCreateConversationMessageApi(state.sectionInfo)
                        emit('success', 'edit', data) 
                    } else {
                        emit('success', state.sectionInfo)
                    }
                    state.show = false
                } catch (error) {
                    showError(error)
                }
                state.loading = false
                state.sectionInfo = {}
            }
        })
        onMounted(() => {
            setTimeout(() => {
                if( userPromptValue.value&& userPromptValue.value.$el){
                const textarea = userPromptValue.value.$el.querySelector('.el-textarea__inner');
                let height = 74
                const currentHeight = textarea.scrollHeight;
                if (currentHeight > 92 && currentHeight < 115) {
                    height = 95
                } else if (currentHeight >= 115 && currentHeight < 136) {
                    height = 117
                } else if (currentHeight >= 136) {
                    height = 140
                }
                textarea.style.height = `${height}px`;}
            }, 0);
            buildAndSortLocaleOptions()
        })
        return {
            ConversationStatusType,
            wordColors,
            wordShapes,
            locale,
            localeOptions,
            instruct,
            errorMessage,
            EntityType,
            ...toRefs(state),
            userPromptValue,
            buildAndSortLocaleOptions
        }
    }
})
