
























import { defineComponent, inject, watch, ref,onMounted} from '@vue/composition-api'
import TypeSelect from './TypeSelect.vue'
import Editor from '@/components/Editor.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { useEventBus } from '@/hooks/useEventBus'
import { UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { Switch } from 'element-ui'
import { cloneDeep } from 'lodash'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        TypeSelect,
        Editor,
        TimeCustomSelect,
        [Switch.name]: Switch,
        SettingBox,
    },
    setup() {
        const { busEmit, busOn } = useEventBus()

        const itemObj: any = inject('itemObj')
        const setIsCollapse = ref(true)

        let isUpdate = false
        const onChange = () => {
            if (isUpdate) {
                isUpdate = false
                return
            }
            localStorage.setItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`, JSON.stringify(itemObj));
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        watch(
            () => itemObj.message_entity.content,
            () => onChange(),
        )

        const onBusOn = () => {
            busOn(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                const { message_entity, id } = data
                if (id === itemObj.id) {
                    Object.keys(itemObj.message_entity).forEach((key) => {
                        if (key != 'content') {
                            itemObj.message_entity[key] = message_entity[key]
                        }
                    })

                    isUpdate = true
                    const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    if (storedItem) {
                        const { message_entity, id } = JSON.parse(storedItem)
                        // 将本地存储的值设置回输入框
                        Object.keys(itemObj.message_entity).forEach((key) => {
                                itemObj.message_entity[key] = message_entity[key]
                        })
                    }
                    localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                }
                if (itemObj.message_entity.settings.length === 0) {
                    itemObj.message_entity.settings.push({
                        ai_enable:true,
                        options: {
                            randomized: false,
                            other: false,
                            none: false,
                        },
                        recommend_enable: true,
                    });
                }
            })
        }
        onBusOn()
        onMounted(() => {
            const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            if (storedItem) {
                const { message_entity, id } = JSON.parse(storedItem)
                Object.keys(itemObj.message_entity).forEach((key) => {
                        itemObj.message_entity[key] = message_entity[key]
                        onChange()
                })
            }
        });
        return {
            ProjectFormType,
            EntityType,
            itemObj,
            onChange,
            setIsCollapse,
        }
    }
})
