






























import { uploadVideo } from '@/api/common'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
import VideoCheckbox from './components/VideoCheckbox.vue'
import Editor from '@/components/Editor.vue'
import { useEventBus } from '@/hooks/useEventBus'
import { showError } from '@/utils/common'
import { timeFormatStr } from '@/utils/time-format'
import { videoTypes, uploadHandle } from '@/utils/upload'
import { defineComponent, inject, nextTick, reactive,watch,computed } from '@vue/composition-api'
import { Upload } from 'element-ui'
import TypeSelect from './TypeSelect.vue'

export default defineComponent({
    components: {
        [Upload.name]: Upload,
        TypeSelect,
        Editor,
        VideoCheckbox
    },
    setup() {
        const { busEmit } = useEventBus()
        const itemObj: any = inject('itemObj')
        const videoUploadInfo = reactive({
            loading: false,
            isTestGroup:computed(()=> itemObj.message_entity.test_group_id!=0 ),
            testGroupName:(itemObj.message_entity.test_group?itemObj.message_entity.test_group.name:''),
            duration: '',
            before: (file: File) => {
                return uploadHandle(file, 2)
            },
            request: async (value: any) => {
                videoUploadInfo.loading = true
                try {
                    const { url } = await uploadVideo({
                        file: value.file
                    })
                    itemObj.message_entity.link = url
                    console.log(itemObj)

                    busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
                } catch (error) {
                    showError(error)
                }
                videoUploadInfo.loading = false
            }
        })

        const onVideoCanplay = () => {
            nextTick(() => {
                const videoRef = document.getElementById(`videoRef-${itemObj.message_entity.id}`) as HTMLVideoElement
                if (videoRef) {
                    itemObj.message_entity.duration = Math.round(videoRef.duration)
                    videoUploadInfo.duration = timeFormatStr(videoRef.duration)
                }
            })
        }

        const onChange = () => {
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        watch(
            () => itemObj.message_entity.content,
            () => onChange()
        )
        return {
            ProjectFormType,
            EntityType,
            videoTypes,
            videoUploadInfo,
            onVideoCanplay,
            itemObj
        }
    }
})
