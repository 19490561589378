































import { updateSectionTestGroupApi​ } from '@/api/project'
import { EntityType, ProjectFormType,UpdateSectionTestGroupDto } from '@/api/project/model'
import { showError } from '@/utils/common'
import { computed, ComputedRef, defineComponent, reactive, toRefs } from '@vue/composition-api'
import ShowFormWrap from '@/components/form-common-show/ShowFormWrap.vue'
export default defineComponent({
    components: {
        ShowFormWrap
    },
    props: {
        testGroup: {
            type: Array,
            default: []
        }
    },
    setup(props, { root, emit }) {
        const testGroupList: ComputedRef<any[]> = computed(() => {
            return props.testGroup || []
        })
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const state = reactive({
            show: false,
            testGroupId: '',
            loading: false,
            location: 'tail',
            isCollapse: false,
            message_entity: {} as any,
            testGroupInfo: {} as any,
            itemObj: {} as any,
            onOpen: (item: any) => {
                state.itemObj=item
                state.testGroupId = item.message_entity.test_group_id!=0?item.message_entity.test_group_id:''
               
                state.show = true
            },
            onChange:(val:any)=>{
                let testGroup=testGroupList.value.filter((item:any)=>val==item.id)
                state.testGroupInfo=testGroup.length>0?testGroup[0]:null
            },
            onSave: async () =>{
                try {
                        const option:UpdateSectionTestGroupDto={
                            uuid: projectUuId.value,
                            sectionId: state.itemObj.section_id,
                            messageId: state.itemObj.message_entity_id,
                            test_group_id:Number(state.testGroupId==''?0:state.testGroupId)
                        }
                        
                        const data = await updateSectionTestGroupApi​(option)
                        state.itemObj.message_entity.test_group_id=Number(state.testGroupId==''?0:state.testGroupId)
                        state.itemObj.message_entity.test_group=state.testGroupInfo
                        emit('success', state.itemObj)
                        state.show = false
                    } catch (error) {
                        showError(error)
                    }
                   
            }
        })

        return {
            ProjectFormType,
            EntityType,
            testGroupList,
            ...toRefs(state)
        }
    }
})
