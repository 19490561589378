




















































































































































































































































































































































































import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, provide, reactive, Ref, ref, set, h } from '@vue/composition-api'
import { Tooltip, Popover, Row, Col, Upload, Checkbox } from 'element-ui'
import TitleTooltip from '@/components/TitleTooltip.vue'
import Avatar from '@/components/Avatar.vue'
import InputAlter from '@/components/InputAlter.vue'
import FormWrap from '@/components/form-common-edit/FormWrap.vue'
import ProjectSharedDialog from './components/ProjectSharedDialog.vue'
import ProjectWordCloudDialog from './components/ProjectWordCloudDialog.vue'
import ProjectAIAnalyzeDialog from './components/ProjectAIAnalyzeDialog.vue'

import {
    copyOnboardingPollApi,
    copySectionApi,
    copySectionMessageApi,
    deleteOnboardingPollApi,
    deleteSectionApi,
    deleteSectionMessageApi,
    excelGuideExportApi,
    excelGuideImportApi,
    getConversationsApi,
    getOnboardingPollsApi,
    getProjectApi,
    moveOnboardingPollApi,
    moveSectionApi,
    moveSectionMessageApi,
    updateOrCreateConversationMessageApi,
    updateOrCreateOnboardingPollApi,
    updateOrCreateSectionApi,
    testGroupsApi,
    deleteTestGroupApi,
} from '@/api/project'
import { exportFile, goUrl, showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import Draggable from 'vuedraggable'
import ProjectItemPopover from './components/ProjectItemPopover.vue'
import ProjectSectionSort from './components/ProjectSectionSort.vue'
import {
    EntityType,
    EntityTypeOption,
    ProjectFormType,
    ProjectStatus,
    UpdateOrCreateConversationMessageDto,
    UpdateOrCreateOnboardingPollsDto,
    UpdateOrCreateSectionDto
} from '@/api/project/model'
import _ from 'lodash'
import { useEventBus } from '@/hooks/useEventBus'
import { UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import ProjectItemMove from './components/ProjectItemMove.vue'
import ProjectItemTestGroupsDialog from './components/ProjectItemTestGroupsDialog.vue'
import { excelTypes, uploadHandle } from '@/utils/upload'
import { entityTypeTemplate, handleNlpSettings } from '@/api/project/entity-type-template'
import { typeDurationOption } from '@/api/project/duration-option'
import { useUserStore } from '@/pinia/modules/user'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import ProjectSurveyError from './components/ProjectSurveyError.vue'
import { ConversationStatusType } from '@/api/project/model'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
import { UserRole } from '@/api/user/model'
import { OUTLINE_IMPORT_TEMPLATE_URL } from '@/config/constant'
import { ARTClient, ARTEvent, ARTNotifyEventType, LoginGuard } from '@/services/sdk'
import { LocaleType } from '@/interface/common'

export default defineComponent({
    components: {
        [Upload.name]: Upload,
        [Tooltip.name]: Tooltip,
        [Popover.name]: Popover,
        [Row.name]: Row,
        [Col.name]: Col,
        [Checkbox.name]: Checkbox,
        ProjectHeader,
        Avatar,
        TitleTooltip,
        InputAlter,
        FormWrap,
        Draggable,
        ProjectItemPopover,
        ProjectSectionSort,
        ProjectItemMove,
        ProjectSurveyError,
        ProjectSharedDialog,
        ProjectWordCloudDialog,
        ProjectAIAnalyzeDialog,
        ProjectItemTestGroupsDialog
    },
    setup(props, { root, refs }) {
        const { busOn, busOff, busEmit } = useEventBus()
        const userStore = useUserStore()
        const { userInfo } = storeToRefs(userStore)

        const projectStore = useProjectStore()
        const { surveySaveState, projectInfo } = storeToRefs(projectStore)
        onMounted(() => {
            document.addEventListener('click', handleActiveId)
            wsInfo.initWsSdk()
        })
        onUnmounted(() => {
            document.removeEventListener('click', handleActiveId)
            busOff()
            wsInfo.clear()
        })
        const activeId = ref(0)
        const handleActiveId = (e: Event) => {
            const classList = document.getElementsByClassName('content-unit') as unknown as any[]
            let has = false
            for (let index = 0; index < classList.length; index++) {
                const element: any = classList[index]
                if (element.contains(e.target)) {
                    has = true
                    break
                }
            }
            if (!has) {
                activeId.value = 0
            }
        }

        const projectUuId = computed(() => root.$route.params.projectUuId)
        const detailObj: any = reactive({})
        provide('detailObj', detailObj)

        const getProject = async () => {
            try {
                const { details, owner } = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
                projectSharedInfo.show = userInfo.value.role == UserRole.Super || owner?.user_id == userInfo.value.id
            } catch (error) {
                showError(error)
            }
        }
        getProject()


        const projectSharedDialogRef = ref()
        const projectSharedInfo = reactive({
            show: false,
            open() {
                projectSharedDialogRef.value.init(projectUuId.value)
            }
        })


        const guideObj = reactive({
            totalTime: computed(() => {

                return conversationObj.totalTime
            }),

            totalCount: computed(() => {
                return conversationObj.totalCount + baseObj.polls.length
            })
        })


        const getTotalTime = (items: any[]) => {
            let totalTime = 0
            items.forEach((item: any) => {
                const defaultList = [
                    EntityType.onboarding_poll,
                    EntityType.onboarding_poll_multi,
                    EntityType.onboarding_poll_picture,
                    EntityType.onboarding_poll_picture_multi,
                    EntityType.onboarding_ask_opinion,
                    // EntityType.speak,
                    // EntityType.image
                ]

                if (defaultList.includes(item.message_entity.entity_type)) {
                    totalTime += 15
                } else if (item.message_entity.entity_type === EntityType.video) {
                    let videoTime = 0
                    item.message_entity.options.forEach((v: any) => {
                        if (v.duration) {
                            videoTime += v.duration + 1
                        }
                    })
                    totalTime += videoTime == 0 ? 15 : videoTime
                } else if (!!item.message_entity.test_group) {
                    let time = 0
                    time = item.message_entity.duration * item.message_entity.test_group.rounds
                    totalTime += time
                } else {
                    totalTime += item.message_entity.duration
                }
            })
            return totalTime
        }


        const baseObj = reactive({
            isCollapse: true,
            id: 0,
            polls: [] as any[]
        })
        busOn(
            UPDATE_BASIC_MESSAGE_ENTITY,
            _.debounce(async (itemObj: any) => {
                try {
                    const data = await updateOrCreateOnboardingPollApi(itemObj)
                    const index = baseObj.polls.findIndex((v) => v.id == data.id)
                    set(baseObj.polls, index, data)
                    busEmit(UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, data)
                    projectStore.handleSurveySaveState()
                    updateBranch(itemObj.message_entity_id)
                } catch (error) {
                    surveySaveState.value = 0
                    showError(error)
                }
            }, 1500)
        )

        const pollObj = reactive({
            onCreate: async (val: EntityTypeOption, index?: number) => {
                const option: UpdateOrCreateOnboardingPollsDto = {
                    id: undefined,
                    project_uuid: projectUuId.value,
                    guide_id: baseObj.id,
                    sequence: index! >= 0 ? index! + 1 : baseObj.polls.length,
                    message_entity: _.cloneDeep(entityTypeTemplate) as any
                }
                option.message_entity.duration = val.duration!
                option.message_entity.entity_type = val.value
                //只有中文和英文才能用AI评分
                if (option.message_entity.settings) {
                    option.message_entity.settings[0].ai_enable = [LocaleType.en_US, LocaleType.zh_CN, LocaleType.zh_TW].includes(detailObj.language)
                }
                if ([EntityType.onboarding_poll, EntityType.onboarding_poll_multi].includes(val.value)) {
                    option.message_entity.options = [{ text: '' }] as any
                }
                try {
                    const data = await updateOrCreateOnboardingPollApi(option)
                    if (index! >= 0) {
                        baseObj.polls.insert(index! + 1, data)
                        await pollObj.onMove()
                    } else {
                        baseObj.polls.push(data)
                    }
                } catch (error) {
                    showError(error)
                }
            },
            onDelete: async (item: any, index: number) => {
                let message = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg1')
                const branchCount = conversationObj.hasBranchIds.filter((j: number) => j == item.message_entity_id).length
                if (branchCount > 0) {
                    message = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg2', { branchCount })
                }
                root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message,
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            try {
                                instance.confirmButtonLoading = true
                                if (branchCount > 0) {
                                    await deleteBranch([item.message_entity_id])
                                }
                                await deleteOnboardingPollApi({
                                    uuid: projectUuId.value,
                                    id: item.id
                                })
                                baseObj.polls.splice(index, 1)
                                await pollObj.onMove()
                                root.$message.success(root.$t('deleteSuccess').toString())
                                done()
                                instance.confirmButtonLoading = false
                            } catch (error) {
                                showError(error)
                            }
                        } else {
                            done()
                        }
                    }
                }).catch(() => { })
            },
            onMove: async () => {
                const sortData: any = {}
                baseObj.polls.forEach((item, index) => {
                    sortData[item.id] = index
                })
                try {
                    await moveOnboardingPollApi({
                        uuid: projectUuId.value,
                        data: sortData
                    })
                    baseObj.polls.forEach((item, index) => {
                        item.sequence = index
                    })
                } catch (error) {

                }
            },
            onCopy: async (item: any, index: number) => {
                try {
                    const data = await copyOnboardingPollApi({
                        uuid: projectUuId.value,
                        id: item.id
                    })
                    baseObj.polls.insert(index + 1, data)
                    await pollObj.onMove()
                    root.$message.success(root.$t('copySuccess').toString())
                } catch (error) {
                    showError(error)
                }
            },
            className: (itemObj: any) => {
                let is_tag=itemObj.message_entity.is_tag
                if (projectInfo.value.status === ProjectStatus.End) {
                    return 'disabled'
                } else if ([ProjectStatus.Start, ProjectStatus.Published].includes(projectInfo.value.status)||is_tag) {
                    return 'halfway'
                } else {
                    return 'active'
                }
            },
            isDisabled: computed(() => [ProjectStatus.End, ProjectStatus.Start, ProjectStatus.Published].includes(projectInfo.value.status)),
            handleToolbar: (itemObj: any) => {
                let tools: string[] = []
                let is_tag=itemObj.message_entity.is_tag
                if (is_tag) {
                    tools.push('删除')
                    if (
                        !verifyMessageEntity(itemObj) &&
                        [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(itemObj.message_entity.entity_type) &&

                        conversationObj.sections.length > 0
                    ) {
                        tools.push('分支追问')
                    }
                } else {
                    if (projectInfo.value.status == ProjectStatus.Pending) {
                        tools.push('复制', '添加', '删除')
                    }

                    if (
                        !verifyMessageEntity(itemObj) &&
                        [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(itemObj.message_entity.entity_type) &&

                        conversationObj.sections.length > 0
                    ) {
                        tools.push('分支追问')
                    }
                }
                return tools
               
            }
        })


        const getOnboardingPolls = async () => {
            if (!projectUuId.value) {
                return
            }
            try {
                const { id, polls } = await getOnboardingPollsApi({
                    uuid: projectUuId.value
                })
                baseObj.id = id
                baseObj.polls = polls
            } catch (error) {
                showError(error)
            }
        }


        const conversationObj = reactive({
            isCollapse: true,
            id: 0,
            isDeleteMaterial: false,
            isDeleteTestGruop: false,
            nowTestGruopName: '',
            confirmButtonLoading: false,
            totalTime: computed(() => {

                const items = [] as any[]
                conversationObj.sections.forEach((item: any) => {
                    items.push(...item.items)
                })
                return getTotalTime(items)
            }),
            totalCount: computed(() => {
                let total = 0
                conversationObj.sections.forEach((item) => {
                    total += item.items.length
                })
                return total
            }),
            sections: [] as any[],
            testGroups: [] as any[],
            hasBranchIds: computed(() => {
                const { sections } = conversationObj
                const hasBranchIds: any[] = []
                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity?.parent_id) {
                            hasBranchIds.push(item.message_entity.parent_id)
                        }
                    })
                })
                return hasBranchIds
            })
        })
        provide('conversationObj', conversationObj)
        const colors = ['#00B6FF', '#9EE900', '#802AB7', '#EB0064', '#FEDB00', '#AEAE9F', '#8ED9F7', '#CEF085', '#C285EA', '#FF95C2', '#FFF096', 'none']
        const getColor = () => {
            const colorTotal = colors.filter((v) => v != 'none').length
            let number = conversationObj.sections.length
            while (number >= colorTotal) {
                number = number - colorTotal
            }
            return colors[number]
        }

        const sectionObj = reactive({
            onCreate: async () => {
                const option: UpdateOrCreateSectionDto = {
                    uuid: projectUuId.value,
                    section_name: 'New Section',
                    color: getColor(),
                    guide_id: conversationObj.id,
                    sequence: conversationObj.sections.length
                }
                try {
                    const data = await updateOrCreateSectionApi(option)
                    data.isCollapse = true
                    conversationObj.sections.push(data)
                } catch (error) {
                    showError(error)
                }
            },
            onInsert: async (location: string, item: any, i: number) => {
                const option: UpdateOrCreateSectionDto = {
                    uuid: projectUuId.value,
                    section_name: 'New Section',
                    color: getColor(),
                    guide_id: conversationObj.id,
                    sequence: conversationObj.sections.length
                }
                try {
                    const data = await updateOrCreateSectionApi(option)
                    data.isCollapse = true
                    if (location == 'before') {
                        conversationObj.sections.insert(i, data)
                    } else if (location == 'after') {
                        conversationObj.sections.insert(i + 1, data)
                    }
                    await sectionObj.onMove()
                        ; (refs[`sectionPopoverRef-${item.id}`] as any)[0].doClose()
                } catch (error) {
                    showError(error)
                }
            },
            onUpdate: async (item: any, index: number, name?: string, color?: string) => {
                console.log(item, index, name, color)
                try {
                    const option: UpdateOrCreateSectionDto = {
                        uuid: projectUuId.value,
                        section_name: name || item.section_name,
                        color: color || item.color,
                        guide_id: conversationObj.id,
                        id: item.id,
                        sequence: item.sequence
                    }
                    const data = await updateOrCreateSectionApi(option)
                    Object.keys(conversationObj.sections[index]).forEach((key) => {
                        if (!['items', 'isCollapse'].includes(key)) {
                            conversationObj.sections[index][key] = data[key]
                        }
                    })
                } catch (error) {
                    showError(error)
                }
            },
            onNameChange: async (name: string, item: any, index: number) => sectionObj.onUpdate(item, index, name),
            onColorChange: async (color: string, item: any, index: number) => sectionObj.onUpdate(item, index, undefined, color),
            onDelete: async (item: any, index: number) => {

                const hasSended = item.items.some((v: any) => v.status == ConversationStatusType.SENDED)
                if (hasSended) {
                    return root.$message.info(<string>root.$i18n.t('projectSurvey.sectionsInfo.deleteMsg1'))
                }
                try {
                    let message = <string>root.$i18n.t('projectSurvey.sectionsInfo.deleteMsg2')
                    if (item.items?.length > 0) {
                        message = <string>root.$i18n.t('projectSurvey.sectionsInfo.deleteMsg3', { number: item.items.length })
                    }
                    await root.$msgbox({
                        showCancelButton: true,
                        confirmButtonText: `${root.$t('confirm')}`,
                        cancelButtonText: `${root.$t('cancel')}`,
                        dangerouslyUseHTMLString: true,
                        message
                    })
                    if (item.items?.length > 0) {
                        await deleteBranch(item.items.map((v: any) => v.message_entity_id))
                    }
                    await deleteSectionApi({
                        uuid: projectUuId.value,
                        sectionId: item.id
                    })
                    conversationObj.sections.splice(index, 1)
                    await sectionObj.onMove()
                    root.$message.success(root.$t('deleteSuccess').toString())
                } catch (error) {
                    if (error != 'cancel') {
                        showError(error)
                    }
                }
            },
            onCopy: async (item: any, index: number) => {
                try {
                    const data = await copySectionApi({
                        uuid: projectUuId.value,
                        sectionId: item.id
                    })
                    data.isCollapse = true
                    conversationObj.sections.insert(index + 1, data)
                    await sectionObj.onMove()
                    root.$message.success(root.$t('copySuccess').toString())
                } catch (error) {
                    showError(error)
                }
            },
            onMove: async () => {
                const sortData: any = {}
                conversationObj.sections.forEach((item, index) => {
                    sortData[item.id] = index
                })
                try {
                    await moveSectionApi({
                        uuid: projectUuId.value,
                        data: sortData
                    })
                    conversationObj.sections.forEach((item, index) => {
                        item.sequence = index
                    })
                } catch (error) {

                }
            },
            onTestGroups: async () => {
                try {
                    const data = await testGroupsApi({
                        uuid: projectUuId.value,
                    })
                    conversationObj.testGroups = data.filter((v: any) => v.status == "ready")
                    conversationObj.testGroups = data
                } catch (error) {

                }
            }
        })


        busOn(
            UPDATE_SESSION_MESSAGE_ENTITY,
            _.debounce(async (itemObj: any) => {
                try {
                    const data = await updateOrCreateConversationMessageApi(itemObj)
                    const section = conversationObj.sections.find((v) => v.id == data.section_id)
                    const index = section.items.findIndex((v: any) => v.id == data.id)
                    set(section.items, index, data)
                    busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, data)
                    projectStore.handleSurveySaveState()
                    updateBranch(itemObj.message_entity_id)
                } catch (error) {
                    surveySaveState.value = 0
                    showError(error)
                }
            }, 1500)
        )

        const messageObj = reactive({
            nowMessageObj: {
                obj: {} as any,
                i: 0 as number,
                item: {} as any,
                index: 0 as number,
                test_group_id: 0 as number
            },
            onCreate: async (val: EntityTypeOption, obj: any, index?: number) => {
                const option: UpdateOrCreateConversationMessageDto = {
                    id: undefined,
                    project_uuid: projectUuId.value,
                    section_id: obj.id,
                    guide_id: conversationObj.id,
                    sequence: index! >= 0 ? index! + 1 : obj.items.length,
                    message_entity: _.cloneDeep(handleNlpSettings(conversationObj.sections)) as any
                }
                if([EntityType.speak, EntityType.image].includes(val.value)){
                    option.message_entity.duration=10
                }else{
                    option.message_entity.duration = val.duration!
                }
                option.message_entity.entity_type = val.value
                if ([EntityType.poll, EntityType.poll_multi, EntityType.rank].includes(val.value)) {
                    option.message_entity.options = [{ text: '' }] as any
                }
                if (val.value == EntityType.ask_opinion) {
                    option.message_entity.ai_settings = projectInfo.value.ai_settings[EntityType.ask_opinion]
                } else if (val.value == EntityType.branch) {
                    option.message_entity.ai_settings = projectInfo.value.ai_settings[EntityType.branch]
                } else if (val.value == EntityType.upload_image) {
                    option.message_entity.ai_settings = projectInfo.value.ai_settings[EntityType.upload_image]
                }
                try {
                    const data = await updateOrCreateConversationMessageApi(option)
                    if (index! >= 0) {
                        obj.items.insert(index! + 1, data)
                        await messageObj.onMove(obj)
                    } else {
                        obj.items.push(data)
                    }
                } catch (error) {
                    showError(error)
                }
            },
            onDelete: async (obj: any, i: number, item: any, index: number) => {
                let message = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg1')
                const branchCount = conversationObj.hasBranchIds.filter((j: number) => j == item.message_entity_id).length
                const formType = item.message_entity.entity_type
                if (branchCount > 0) {
                    message = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg2', { branchCount })
                }
                const sectionName = item.message_entity.test_group ? item.message_entity.test_group.name : ''
                const testGroupId = item.message_entity.test_group_id
                if ((formType == 'image' || formType == 'video') && testGroupId != 0) {
                    conversationObj.nowTestGruopName = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg3', { sectionName })
                    messageObj.nowMessageObj.obj = obj
                    messageObj.nowMessageObj.i = i
                    messageObj.nowMessageObj.item = item
                    messageObj.nowMessageObj.index = index
                    messageObj.nowMessageObj.test_group_id = testGroupId
                    conversationObj.isDeleteMaterial = true
                    conversationObj.isDeleteTestGruop = false
                } else {
                    root.$msgbox({
                        showCancelButton: true,
                        confirmButtonText: `${root.$t('confirm')}`,
                        cancelButtonText: `${root.$t('cancel')}`,
                        dangerouslyUseHTMLString: true,
                        message: message,
                        beforeClose: async (action, instance, done) => {
                            if (action === 'confirm') {
                                try {
                                    instance.confirmButtonLoading = true
                                    if (branchCount > 0) {
                                        await deleteBranch([item.message_entity_id])
                                    }
                                    await deleteSectionMessageApi({
                                        uuid: projectUuId.value,
                                        sectionId: obj.id,
                                        messageId: item.id
                                    })
                                    conversationObj.sections[i].items.splice(index, 1)
                                    await messageObj.onMove(obj)
                                    root.$message.success(root.$t('deleteSuccess').toString())
                                    done()
                                    instance.confirmButtonLoading = false
                                } catch (error) {
                                    showError(error)
                                }
                            } else {
                                done()
                            }
                        }
                    }).catch(() => { })
                }

            },

            onMove: async (obj: any) => {
                const { items, id } = obj
                const sortData: any = {}
                items.forEach((item: any, index: number) => {
                    sortData[item.id] = index
                })
                try {
                    await moveSectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: id,
                        data: sortData
                    })
                    obj.items.forEach((item: any, index: number) => {
                        item.sequence = index
                    })
                } catch (error) {

                }
            },
            onCopy: async (obj: any, i: number, item: any, index: number) => {
                try {
                    const data = await copySectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: obj.id,
                        messageId: item.id
                    })
                    obj.items.insert(index + 1, data)
                    await messageObj.onMove(obj)
                    root.$message.success(root.$t('copySuccess').toString())
                } catch (error) {
                    showError(error)
                }
            },
            className: (itemObj: any) => {
              
                if (projectInfo.value.status === ProjectStatus.End) {
                    return 'disabled'
                } else if (([ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.value.status) && itemObj.status === ConversationStatusType.SENDED)||(projectInfo.value.isAutoSend&&[ProjectStatus.Start].includes(projectInfo.value.status))) {
                    return 'halfway'
                } else {
                    return 'active'
                }
            },
            isDisabled: computed(() => [ProjectStatus.End].includes(projectInfo.value.status)||projectInfo.value.isAutoSend&&[ProjectStatus.Start].includes(projectInfo.value.status)),
            isTestGroupStart: (item: any) => {
                let test_group_rounds = item.message_entity.test_group_rounds
                if (test_group_rounds != null && test_group_rounds.length > 0) {
                    return false
                } else {
                    return true
                }
            },
            onDeleteTestGroup: async () => {
                conversationObj.confirmButtonLoading = true
                try {
                    await deleteSectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: messageObj.nowMessageObj.obj.id,
                        messageId: messageObj.nowMessageObj.item.id
                    })
                    conversationObj.sections[messageObj.nowMessageObj.i].items.splice(messageObj.nowMessageObj.index, 1)
                    await messageObj.onMove(messageObj.nowMessageObj.obj)
                    if (conversationObj.isDeleteTestGruop) {
                        await deleteTestGroupApi({
                            uuid: projectUuId.value,
                            sectionId: messageObj.nowMessageObj.test_group_id
                        })
                        const { sections } = await getConversationsApi({
                            uuid: projectUuId.value
                        })
                        sections.forEach((obj: any, i: number) => {
                            obj.items.forEach((item: any, index: number) => {
                                set(conversationObj.sections[i].items, index, item)
                                busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, item)
                            })
                        })
                    }

                    root.$message.success(root.$t('deleteSuccess').toString())
                    conversationObj.isDeleteMaterial = false
                    conversationObj.confirmButtonLoading = false
                } catch (error) {
                    showError(error)
                }
            },
            handleToolbar: (itemObj: any) => {
                let tools: string[] = []
                if ([ProjectStatus.Pending, ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.value.status)) {
                    if (projectInfo.value.status != ProjectStatus.Start || !projectInfo.value.isAutoSend) {
                        tools.push('添加')
                        if (itemObj.message_entity.entity_type !== EntityType.branch) {
                            tools.push('复制')
                        }

                        if (itemObj.status !== ConversationStatusType.SENDED) {
                            tools.push('删除')
                            tools.push('移动')
                            // if (itemObj.message_entity.entity_type !== EntityType.branch) {
                            //     tools.push('移动')
                            // }
                            if (![EntityType.speak, EntityType.video, EntityType.image, EntityType.upload_image, EntityType.branch].includes(itemObj.message_entity.entity_type)) {
                                tools.push('加入测试组')
                            }
                        }
                    }
                }

                if ([EntityType.ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type)) {
                    tools.push('词云')
                }
                if ([EntityType.upload_image].includes(itemObj.message_entity.entity_type)) {
                    tools.push('AI分析')
                }

                if (projectInfo.value.status != ProjectStatus.Start || !projectInfo.value.isAutoSend) {
                if (
                    !verifyMessageEntity(itemObj) &&
                    [EntityType.poll, EntityType.picture_poll].includes(itemObj.message_entity.entity_type) &&

                    conversationObj.sections.length > 0
                ) {
                    tools.push('分支追问')
                }
            }
                return tools
            },
            isDisabledTestGroup: false,
            toggleCheckbox: () => {
                messageObj.isDisabledTestGroup = !messageObj.isDisabledTestGroup
            },
        })


        const getConversations = async (init = false) => {
            if (!projectUuId.value) {
                return
            }
            try {
                const { id, sections } = await getConversationsApi({
                    uuid: projectUuId.value
                })
                conversationObj.id = id
                sections.forEach((item: any) => {
                    item.isCollapse = true
                })
                set(conversationObj, 'sections', sections)
            } catch (error) {
                showError(error)
            }
        }


        const createBranch = async (formType: ProjectFormType, item: any) => {
            let section
            let sectionIndex = 0
            let sequence = 0
            let itemIndex = -1
            if (formType == ProjectFormType.Basic) {

                sectionIndex = conversationObj.sections.length - 1
                section = conversationObj.sections[sectionIndex]
                sequence = section.items.length
            } else if (formType == ProjectFormType.Session) {

                sectionIndex = conversationObj.sections.findIndex((section: any) => {
                    return item.section_id == section.id
                })
                section = conversationObj.sections[sectionIndex]
                itemIndex = section.items.findIndex((it: any) => {
                    return item.id == it.id
                })
                sequence = itemIndex + 1
            }
            const option: UpdateOrCreateConversationMessageDto = {
                id: undefined,
                project_uuid: projectUuId.value,
                section_id: section.id,
                guide_id: conversationObj.id,
                sequence: sequence,
                message_entity: _.cloneDeep(handleNlpSettings(conversationObj.sections)) as any
            }
            option.message_entity.duration = typeDurationOption[EntityType.branch].value
            option.message_entity.entity_type = EntityType.branch
            option.message_entity.parent_id = item.message_entity_id
            option.message_entity.branchs = [
                {
                    message_entity_id: item.message_entity.id,
                    name: '--',
                    options: [],
                    content: ''
                },
                {
                    message_entity_id: item.message_entity.id,
                    name: '--',
                    options: [],
                    content: ''
                }
            ] as any
            option.message_entity.ai_settings = projectInfo.value.ai_settings[EntityType.branch]

            try {
                const data = await updateOrCreateConversationMessageApi(option)
                if (itemIndex >= 0) {
                    conversationObj.sections[sectionIndex].items.insert(itemIndex + 1, data)
                } else {
                    conversationObj.sections[sectionIndex].items.push(data)
                }
                await messageObj.onMove(section)
            } catch (error) {
                showError(error)
            }
        }


        const deleteBranch = async (parentIds: number[]) => {
            const sections = conversationObj.sections
            for (let i = 0; i < sections.length; i++) {
                const obj = sections[i]
                for (let index = obj.items.length - 1; index >= 0; index--) {
                    const item = obj.items[index]
                    if (parentIds.includes(item.message_entity.parent_id)) {
                        await deleteSectionMessageApi({
                            uuid: projectUuId.value,
                            sectionId: obj.id,
                            messageId: item.id
                        })
                        conversationObj.sections[i].items.splice(index, 1)
                        await messageObj.onMove(obj)
                    }
                }
            }
        }


        const updateBranch = async (parentId: number) => {
            console.log(parentId)
            if (conversationObj.hasBranchIds.includes(parentId)) {
                console.log(`存在分支`)
                try {
                    const { sections } = await getConversationsApi({
                        uuid: projectUuId.value
                    })
                    sections.forEach((obj: any, i: number) => {
                        obj.items.forEach((item: any, index: number) => {
                            if (parentId == item.message_entity.parent_id) {
                                console.log(`找到对应分支进行赋值`)
                                set(conversationObj.sections[i].items, index, item)
                                busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, item)
                            }
                        })
                    })
                } catch (error) {
                    showError(error)
                }
            }
        }


        const projectSectionSortRef: Ref<any> = ref(null)
        const sectionSortInfo = reactive({
            onOpen: () => {
                projectSectionSortRef.value.onOpen(conversationObj.sections)
            },
            onSuccess: () => getConversations()
        })


        const projectItemMoveRef: Ref<any> = ref(null)
        const itemMoveInfo = reactive({
            onOpen: (item: any) => {
                projectItemMoveRef.value.onOpen(item)
            },
            onSuccess: () => {
                getConversations()
            }
        })


        const projectWordCloudDialogRef: Ref<any> = ref(null)
        const wordCloudInfo = reactive({
            onOpen: (item: any) => {
                projectWordCloudDialogRef.value.onOpen(item)
            },
            onSuccess: async (type: string, itemObj: any) => {
                console.log(type, itemObj)


                await getConversations()
                conversationObj.sections.forEach((item) => {
                    item.items.forEach((info: any) => {
                        busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, info)
                    })
                })
            }
        })
        const projectAIAnalyzeDialogRef: Ref<any> = ref(null)

        const aiAnalyseInfo = reactive({
            onOpen: (item: any) => {
                projectAIAnalyzeDialogRef.value.onOpen(item)
            },
            onSuccess: async (type: string, itemObj: any) => {
                await getConversations()
                conversationObj.sections.forEach((item) => {
                    item.items.forEach((info: any) => {
                        busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, info)
                    })
                })
            }
        })

        const projectItemTestGroupsRef: Ref<any> = ref(null)
        const itemTestGroups = reactive({
            onOpen: (item: any) => {
                sectionObj.onTestGroups()
                projectItemTestGroupsRef.value.onOpen(item)
            },
            onSuccess: async (itemObj: any) => {
                await getConversations()
                busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, itemObj)
                updateBranch(itemObj.message_entity.id)
            }
        })

        const initData = async () => {
            const promiseList = [getOnboardingPolls(), getConversations()]
            await Promise.all(promiseList)
        }
        initData()


        const excelMngObj = reactive({
            loading: false,
            isSuccess: false,
            before: (file: File) => {
                return uploadHandle(file, 5)
            },
            request: async (value: any) => {
                excelMngObj.loading = true
                excelMngObj.isSuccess = false
                try {
                    await excelGuideImportApi({
                        file: value.file,
                        uuid: projectUuId.value
                    })
                    initData()
                    excelMngObj.isSuccess = true
                } catch (error) {
                    showError(error)
                }
                excelMngObj.loading = false
            },
            export: async () => {
                try {
                    const data = await excelGuideExportApi({
                        uuid: projectUuId.value
                    })
                    exportFile(data, detailObj.project_name + '_' + new Date().getTime() + '.xlsx')
                } catch (error) {
                    showError(error)
                }
            }
        })

        const downloadGuideExcelTemplat = () => goUrl(`${OUTLINE_IMPORT_TEMPLATE_URL}?attname=${root.$i18n.t('projectSurvey.templatInfo.xlsx')}`)

        const refreshAllData = () => {
            baseObj.polls.forEach((item) => {
                busEmit(UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, item)
            })
            conversationObj.sections.forEach((item) => {
                item.items.forEach((info: any) => {
                    busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, info)
                })
            })
        }


        const wsInfo = {

            gatewayURL: `${location.protocol === 'https:' ? 'wss:' : 'ws:'}//${location.host}/gateway`,
            authInfo: {
                token: userStore.userInfo.token ?? '',
                projectUuid: projectUuId.value,
                guard: LoginGuard.Moderator
            },
            cli: {} as ARTClient,
            initWsSdk: async () => {

                wsInfo.cli = new ARTClient(wsInfo.gatewayURL)

                const eventcallback = (evt: ARTEvent, data?: any) => {
                    if (evt == ARTEvent.Notify) {
                        console.log(`通知事件【${evt}】，执行对应执行参数:${JSON.stringify(data)}`)
                        if (data.event == ARTNotifyEventType.QuestionUpdate) {
                            _.debounce(async () => {
                                console.log(`更新题目`)
                            }, 500)()
                        } else if (data.event == ARTNotifyEventType.SegmentUpdate) {
                            _.debounce(() => {
                                console.log(`更新分群`)
                                projectStore.updateProjectSegments(data.projectUUID)
                            }, 1500)()
                        }
                    }
                }
                wsInfo.cli.onmessage((m) => { })
                wsInfo.cli.onofflinemessage((m) => { })
                const evts = [ARTEvent.Closed, ARTEvent.Reconnecting, ARTEvent.Reconnected, ARTEvent.Kickout, ARTEvent.Notify]
                wsInfo.cli.register(evts, eventcallback)

                const { success, err } = await wsInfo.cli.login(wsInfo.authInfo)
                console.log(`login ${success}`)
                if (!success) {
                    console.log(err)
                }
            },
            clear: async () => {
                if (wsInfo.cli) {
                    console.log(`wsInfo cli logout`)
                    await wsInfo.cli.logout()
                    wsInfo.cli = {} as ARTClient
                }
            }
        }

        // onBeforeUnmount(() => {
        //     Object.keys(localStorage).forEach(key => {
        //         if (key.startsWith('UPDATE_SESSION_MESSAGE_ENTITY_')) {
        //         localStorage.removeItem(key);
        //         }
        //     });
        // });


        return {
            ConversationStatusType,
            surveySaveState,
            EntityType,
            activeId,
            projectUuId,
            detailObj,
            baseObj,
            pollObj,
            conversationObj,
            sectionObj,
            guideObj,
            colors,
            messageObj,
            ProjectFormType,
            projectSectionSortRef,
            sectionSortInfo,
            projectItemMoveRef,
            itemMoveInfo,
            createBranch,
            excelMngObj,
            excelTypes,
            projectInfo,
            ProjectStatus,
            projectSharedDialogRef,
            projectSharedInfo,
            downloadGuideExcelTemplat,
            projectWordCloudDialogRef,
            projectAIAnalyzeDialogRef,
            wordCloudInfo,
            aiAnalyseInfo,
            projectItemTestGroupsRef,
            itemTestGroups
        }
    }
})
