






























import { uploadImage } from '@/api/common'
import { updateOrCreateSectionMessageApi } from '@/api/project'
import { EntityType, ProjectFormType, UpdateOrCreateSectionMessageDto } from '@/api/project/model'
import Editor from '@/components/Editor.vue'
import ImageCheckbox from './components/ImageCheckbox.vue'
import { UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
import { useEventBus } from '@/hooks/useEventBus'
import { showError } from '@/utils/common'
import { imageTypes, uploadHandle } from '@/utils/upload'
import { defineComponent, inject, reactive,watch ,computed} from '@vue/composition-api'
import { Upload } from 'element-ui'
import TypeSelect from './TypeSelect.vue'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
export default defineComponent({
    components: {
        [Upload.name]: Upload,
        TypeSelect,
        Editor,
        ImageCheckbox,
        TimeCustomSelect
    },
    setup() {
        const { busEmit } = useEventBus()
        const itemObj: any = inject('itemObj')
        const imageUploadInfo = reactive({
            loading: false,
            isTestGroup:computed(()=> itemObj.message_entity.test_group_id!=0 ),
            testGroupName:itemObj.message_entity.test_group?itemObj.message_entity.test_group.name:'',
            before: (file: File) => {
                return uploadHandle(file, 1)
            },
            request: async (value: any) => {
                imageUploadInfo.loading = true
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    itemObj.message_entity.link = url
                    busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
                } catch (error) {
                    showError(error)
                }
                imageUploadInfo.loading = false
            }
        })

        const onChange = () => {
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        watch(
            () => itemObj.message_entity.content,
            () => onChange()
        )

        return {
            ProjectFormType,
            EntityType,
            imageTypes,
            imageUploadInfo,
            itemObj,
            onChange
        }
    }
})
