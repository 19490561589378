
























































import { ProjectFormType } from '@/api/project/model'
import { UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import { useEventBus } from '@/hooks/useEventBus'
import { computed, defineComponent, inject, nextTick, ref ,onMounted} from '@vue/composition-api'
import _ from 'lodash'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        SettingBox
    },
    setup(props, { refs }) {
        const { busEmit, busOn } = useEventBus()
        const formType: ProjectFormType | undefined = inject('formType')
        const itemObj: any = inject('itemObj')

        const isCollapse = ref(true)
        const setIsCollapse = ref(false)

        const addOption = (index?: number) => {
            const item = {
                text: ''
            }
            let location = 0
            if (index! >= 0) {
                location = index! + 1
                itemObj.message_entity.options.insert(location, item)
            } else {

                const options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                location = options.length
                itemObj.message_entity.options.insert(location, item)
            }
            nextTick(() => (refs.optionInputRef as any)[location].focus())

            onChange()
        }

        const onChange = () => {
            itemObj.message_entity.options.forEach((option: any, index: number) => {
                option.sequence = index
            })
            localStorage.setItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`, JSON.stringify(itemObj));
            onBusEmit()
        }

        const onDeleteOption = async (item: any, index: number) => {
            itemObj.message_entity.options.splice(index, 1)
            onChange()
        }

        const onBusEmit = () => {
            if (formType == ProjectFormType.Basic) {
                busEmit(UPDATE_BASIC_MESSAGE_ENTITY, itemObj)
            } else if (formType == ProjectFormType.Session) {
                busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
            }
        }

        const onBusOn = () => {
            if (formType == ProjectFormType.Basic) {
                busOn(UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                    const { message_entity, id } = data
                    if (id === itemObj.id) {
                        // itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                        if (storedItem) {
                            const { message_entity, id } = JSON.parse(storedItem)
                            itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        }
                        localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    }
                })
            } else if (formType == ProjectFormType.Session) {
                busOn(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                    const { message_entity, id } = data
                    if (id === itemObj.id) {
                        // itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                        if (storedItem) {
                            const { message_entity, id } = JSON.parse(storedItem)
                                    itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        }
                        localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    }
                })
            }
        }
        onBusOn()
        onMounted(() => {
            const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            if (storedItem) {
                const { message_entity, id } = JSON.parse(storedItem)
                if (id === itemObj.id) {
                        itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        onChange()
                }
            }
        });
        return {
            isCollapse,
            setIsCollapse,
            onChange,
            addOption,
            onDeleteOption,
            itemObj
        }
    }
})
