








































import { defineComponent, inject, reactive, watch, ref } from '@vue/composition-api'
import TypeSelect from './TypeSelect.vue'
import Editor from '@/components/Editor.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { useEventBus } from '@/hooks/useEventBus'
import { UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
import _ from 'lodash'
import { sesstionUploadItems } from '@/api/project/entity-type-option'
import TimeCustomSelect from '@/components/TimeCustomSelect.vue'
import { typeDurationOption } from '@/api/project/duration-option'

export default defineComponent({
    components: {
        TypeSelect,
        Editor,
        TimeCustomSelect
    },
    setup(props, { root }) {
        const { busEmit } = useEventBus()
        const itemObj: any = inject('itemObj')
        const entityTypeInfo = reactive({
            value: itemObj.message_entity.entity_type,
            oldValue: itemObj.message_entity.entity_type,
            options: sesstionUploadItems
        })

        const timeCustomSelectRef = ref()

        const onTypeChange = (val: EntityType) => {
            entityTypeInfo.oldValue = val

            if (val === EntityType.upload_video && typeDurationOption[val].value) {
                itemObj.message_entity.duration = typeDurationOption[val].value
            } else {
                itemObj.message_entity.duration = typeDurationOption[EntityType.upload_image].value
            }

            timeCustomSelectRef.value.timeInfo.value = itemObj.message_entity.duration
            onChange()
        }

        const onChange = () => {
            itemObj.message_entity.entity_type = entityTypeInfo.value
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        watch(
            () => itemObj.message_entity.content,
            () => onChange()
        )

        return {
            EntityType,
            ProjectFormType,
            entityTypeInfo,
            onChange,
            itemObj,
            onTypeChange,
            timeCustomSelectRef
        }
    }
})
