






























































































import { uploadImage } from '@/api/common'
import { ProjectFormType } from '@/api/project/model'
import { UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, UPDATE_SESSION_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS } from '@/config/event-bus'
import { useEventBus } from '@/hooks/useEventBus'
import { showError } from '@/utils/common'
import { imageTypes, uploadHandle } from '@/utils/upload'
import { computed, defineComponent, inject, reactive, ref, nextTick ,onMounted} from '@vue/composition-api'
import { Upload } from 'element-ui'
import _ from 'lodash'
import SettingBox from '@/components/SettingBox.vue'

export default defineComponent({
    components: {
        [Upload.name]: Upload,
        SettingBox
    }, 
    props: {
        isShowRight: {
            type: Boolean,
            default: true
        },
        option:{
            type: Boolean,
            default: true
        },
        upLoadNumber:{
            type: Number,
            default: 50
        },
        useFileName:{
            type: Boolean,
            default: false
        }
    },
    setup(props, { root, refs }) {
        const { busEmit, busOn } = useEventBus()
        const formType: ProjectFormType | undefined = inject('formType')
        const itemObj: any = inject('itemObj')
        if( itemObj.message_entity.link!=''&&itemObj.message_entity.link!=null){
            itemObj?.message_entity.options.unshift({
            created_at:  itemObj.message_entity.created_at,
            deleted_at: null,
            id:  null,
            is_show: true,
            is_system: false,
            link: itemObj.message_entity.link,
            message_entity_id: itemObj.message_entity.id,
            option_type: "",
            sequence: 0,
            text: `${root.$i18n.t('project.image')}1`,
            updated_at: null,
            })
            itemObj.message_entity.link=''
        }
        const isCollapse = ref(true)
        const setIsCollapse = ref(false)

        const optionObj = reactive({
            imgs: computed(() => itemObj?.message_entity.options.filter((v: any) => !v.is_system)),
            systemOptions: computed(() => itemObj?.message_entity.options.filter((v: any) => v.is_system))
        })

        const previewList = computed(() => itemObj.message_entity.options.map((v: any) => v.link))

        const imageUploadRef = ref()
        const imageUploadInfo = reactive({
            loading: false,
            before: (file: File) => {
                return uploadHandle(file, 1)
            },
            request: async (value: any) => {
                imageUploadInfo.loading = true
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    let location = 0
                    if (value.data.type == 'edit') {
                        itemObj.message_entity.options[value.data.index].link = url
                        location = value.data.index
                    } else {

                        const index = itemObj.message_entity.options.filter((v: any) => !v.is_system).length
                        const data = {
                            link: url,
                            text:props.useFileName?value.file.name.substring(0, value.file.name.lastIndexOf('.')): `${root.$i18n.t('project.image')}${index + 1}`
                        }
                        if (value.data.index >= 0) {
                            location = value.data.index + 1
                            itemObj.message_entity.options.insert(location, data)
                        } else {

                            const options = itemObj.message_entity.options.filter((item: any) => !item.is_system)
                            location = options.length
                            itemObj.message_entity.options.insert(location, data)
                        }
                    }
                    nextTick(() => {
                        const input = document.getElementById(`optionInputRef${url}`)
                        input?.focus()
                    })
                    onChange()
                } catch (error) {
                    showError(error)
                }
                imageUploadInfo.loading = false
            }
        })

        const onChange = () => {
            itemObj.message_entity.options.forEach((option: any, index: number) => {
                option.sequence = index
            })
            localStorage.setItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`, JSON.stringify(itemObj));
            onBusEmit()
        }

        const onDeleteOption = async (item: any, index: number) => {
            itemObj.message_entity.options.splice(index, 1)
            onChange()
        }

        const onBusEmit = () => {
            if (formType == ProjectFormType.Basic) {
                busEmit(UPDATE_BASIC_MESSAGE_ENTITY, itemObj)
            } else if (formType == ProjectFormType.Session) {
                busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
            }
        }

        const onBusOn = () => {
            if (formType == ProjectFormType.Basic) {
                busOn(UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                    const { message_entity, id } = data
                    if (id === itemObj.id) {
                        const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                        if (storedItem) {
                            const { message_entity, id } = JSON.parse(storedItem)
                                    itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        }
                        localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    }
                })
            } else if (formType == ProjectFormType.Session) {
                busOn(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, (data: any) => {
                    const { message_entity, id } = data
                    if (id === itemObj.id) {
                        const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                        if (storedItem) {
                            const { message_entity, id } = JSON.parse(storedItem)
                                    itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        }
                        localStorage.removeItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
                    }
                })
            }
        }
        onBusOn()
        onMounted(() => {
            const storedItem = localStorage.getItem(`UPDATE_SESSION_MESSAGE_ENTITY_${itemObj.message_entity_id}`);
            if (storedItem) {
                const { message_entity, id } = JSON.parse(storedItem)
                if (id === itemObj.id) {
                        itemObj.message_entity.options = _.cloneDeep(message_entity.options)
                        onChange()
                }
            }
        });
        return {
            imageTypes,
            imageUploadInfo,
            isCollapse,
            setIsCollapse,
            onDeleteOption,
            onChange,
            itemObj,
            optionObj,
            previewList,
            imageUploadRef
        }
    }
})
